import { api } from "@/api/config";

const actions = {
	// async get_token({ commit }) {

	// 	try {

	// 		await api.get('/v1/token?secret=' + process.env.VUE_APP_API_SECRET)
	// 			.then((res) => {
	// 				commit('setToken', res.data.token);
	// 				console.log(res.data.token)
	// 			})
	// 			.catch((error) => {
	// 				console.log(error);
	// 			});

	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// },
	async check_login({commit}) {
		try {
			await api.get('/login-check')
				.then((res) => {
					commit('setLogged', res.status == 204);
				})
				.catch((error) => {
					console.log(error);
					commit('setLogged', false);
				});

		} catch (error) {
			console.log(error);
			commit('setLogged', false);
		}
	},

};

export default actions;
