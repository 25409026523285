
const getters = {
	getToken: (state) => {
		return state.token;
	},
	isLogged: (state) => {
		return state.logged;
	},
	
};

export default getters;
