<template>
  <div class="message">
    <img alt="Cruzeiro do sul" src="@/assets/img/logo_color_cs.svg" class="main-logo">
    <h1 class="message-display">Gerenciador de Conteúdo</h1>
    <p class="message-text">Olá! Clique nos cards abaixo para acessar a área desejada.</p>
  </div>
</template>

<script>
export default {
  
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.message{
  text-align: center;
  margin: 2rem auto 5rem;
  }
  
  .message-display {
    color: var(--color-main);
    font-family: var(--text-heading);
    font-size: 3rem;
    font-weight: 700;
    margin: 2rem auto;
    text-transform: uppercase;
  
  }
  
  .message-text {
    font-size: 1.25rem;
  }
  
  .main-logo {
    width: 30vw;
    max-width: 320px;
    margin-bottom: 2rem;
}
</style>
