import course from './course';
import auth from './auth';
import file from './file';

const modules = {
	course,
	auth,
	file
}

export default modules;