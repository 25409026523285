const state = {
	file: null,
	fileContent: [],
	fileParsed: false,
	currentProgress: 0,
	totalProgress: 0,
	log: {},
	logList: [],
	isLoadingBatchLogs: false
};

export default state;
