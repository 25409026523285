import { api } from "@/api/config";

const actions = {
	// https://www.bezkoder.com/vue-axios-file-upload/
	async sendUpdate({ commit }, payload) {


		let temp = payload.row;


		let header = {}

		api.post(process.env.VUE_APP_HOSTNAME + '/batch/' + payload.action, temp, header)
			.then((res) => {
				commit('upgradeProgress');
				if (res.data.status !== undefined && res.data.status >= 400) {
					console.log(res);
					let row = {
						'content_id': temp.content_id,
						'course_id': temp.course_id,
						'action': payload.action,
						'status': 'fail',
						'message': res.data.message
					};
					commit('addLog', row);
				} else {
					let row = {
						'content_id': temp.content_id,
						'course_id': temp.course_id,
						'action': payload.action,
						'status': 'success',
					};
					commit('addLog', row);
				}


			}).catch((error) => {
				console.log(error);
				commit('setCurrentProgress');
				let row = {
					'content_id': temp.content_id,
					'course_id': temp.course_id,
					'action': payload.action,
					'status': 'fail',
					'message': error
				};
				commit('addLog', row);
				console.log(error);
			});



	},

	async startLog({ commit }) {

		await api.post(process.env.VUE_APP_HOSTNAME + '/batch/log/open')
			.then((res) => {
				commit('startLog', res.data.id);

			}).catch((error) => {
				commit('course/setFeedbackText', 'Erro ao gerar registro de Log. A operação foi cancelada.', { root: true });
				commit('course/setFeedbackType', 'danger', { root: true });
				commit('course/setFeedbackShow', true, { root: true });

				console.log(error);
			});



	},

	async endLog({ dispatch, commit, getters }) {
		commit('course/setFeedbackText', 'Operação finalizada! Gerando Log das alterações.', { root: true });
		commit('course/setFeedbackType', 'success', { root: true });
		commit('course/setFeedbackShow', true, { root: true });



		let temp = { 'log': getters.getLogRows };

		let header = {}

		api.post(process.env.VUE_APP_HOSTNAME + '/batch/log/' + getters.getLogId, temp, header)
			.then(() => {
				commit('course/setFeedbackText', 'Log gerado e disponível para consulta.', { root: true });
				commit('course/setFeedbackType', 'success', { root: true });
				commit('course/setFeedbackShow', true, { root: true });
				dispatch('loadLogs');


			}).catch((error) => {
				commit('course/setFeedbackText', 'Erro ao gerar registro de Log.', { root: true });
				commit('course/setFeedbackType', 'danger', { root: true });
				commit('course/setFeedbackShow', true, { root: true });

				console.log(error);
			});

	},

	async loadLogs({ commit }) {
		commit('setIsLoadingBatchLogs', true);
		await api.get(process.env.VUE_APP_HOSTNAME + '/batch/log/list')
			.then((res) => {

				commit('setLogList', res.data || []);

			})
			.catch((error) => {
				console.log(error);
			});
		commit('setIsLoadingBatchLogs', false);

	},
};

export default actions;
