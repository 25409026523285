
const getters = {

	getFile: (state) => {
		return state.file;
	}, 
	getFileContent: (state) => {
		return state.fileContent;
	}, 

	isFileParsed: (state) => {
		return state.fileParsed;
	},
	
	getCurrentProgress: (state) => {
		return state.currentProgress;
	}, 

	getTotalProgress: (state) => {
		return state.totalProgress;
	}, 

	getLogId: (state) => {
		return state.log.log_id;
	},

	getLogRows: (state) => {
		return state.log.rows;
	}, 

	getLogList: (state) => {
		return state.logList;
	}, 

	getLogRowsSize: (state) => {
		return state.log.rows.length;
	}, 

	getIsLoadingBatchLogs: (state) => {
		return state.isLoadingBatchLogs;
	}, 
};

export default getters;
