<template>
  <AppWelcomeMessage />


  <div class="cards container">

    <AppCard logoSrc="1" @click="$router.push('/sync')">
      <template #title>
        Filiação e Sincronização
      </template>
      <template #text>
        Altere opções de filiação de disciplinas e realize a sincronização
      </template>
    </AppCard>
    <AppCard logoSrc="2" @click="$router.push('/edit')">
      <template #title>
        Edição de Disciplina
      </template>
      <template #text>
        Edite ou exclua itens de uma disciplina
      </template>
    </AppCard>
    <AppCard logoSrc="3" @click="$router.push('/batch')">
      <template #title>
        Ações em Lote
      </template>
      <template #text>
        Realize ações em lote em várias disciplinas
      </template>
    </AppCard>


  </div>


</template>

<script>
// @ is an alias to /src

import AppWelcomeMessage from '@/components/AppWelcomeMessage.vue'
import AppCard from '@/components/AppCard.vue'
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'HomeView',
  components: {
    AppWelcomeMessage,
    AppCard
  },
  computed:{
    ...mapGetters('auth', [
      'isLogged'
    ]),
  },
  async created() {
    await this.check_login()
    if(!this.isLogged) this.$router.push('/login');

  },
  methods: {
    ...mapActions('auth', [
      'check_login'
    ]),
  }
}
</script>

<style>
.cards {
  margin: 2rem auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem
}
</style>