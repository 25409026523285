const mutations = {

	setFile: (state, file) => {
		state.file = file;
		state.fileParsed = false;
	},

	setFileContent: (state, fileContent) => {
		state.fileContent = fileContent;
		state.fileParsed = true;
	},

	upgradeProgress: (state) => {
		state.currentProgress++;
	},
	resetProgress: (state) => {
		state.currentProgress = 0;
	},
	setTotalProgress: (state, totalProgress) => {
		state.totalProgress = totalProgress;
	},

	startLog:(state, log_id) => {
		state.log = {'log_id':log_id, 'rows': []};
	},

	addLog:(state, payload) => {
		state.log.rows.push(payload);
	},

	setLogList(state, logList) {

		logList.forEach(tempLog => {
			if(!tempLog.log != null) {
				tempLog.logObj = JSON.parse(tempLog.log);
			} else {
				tempLog.logObj = {'message': 'Log incompleto e/ou com erro!'};
			}

		});

		state.logList = logList;
	},
	setIsLoadingBatchLogs: (state, isLoadingBatchLogs) => {
		state.isLoadingBatchLogs = isLoadingBatchLogs;
	},

};

export default mutations;
