<template>
    <button type="button" :class="`btn ${btn}`">
        <slot>

        </slot>
    </button>
</template>

<script>
export default {
    props: {
        btn: String
    }
}
</script>

