
const getters = {

	getCourseId: (state) => {
		return state.courseId;
	}, 

	getCourseInfo: (state) => {
		return state.courseInfo;
	},

	getCourseChildren: (state) => {
		return state.courseChildren;
	},

	getCourseLogs: (state) => {
		return state.log;
	},

	getCourseContents: (state) => {
		return state.courseContents;
	},

	hasInfo: (state) => {
		return state.hasInfo;
	},

	isLoading: (state) => {
		return state.isLoading;
	},

	getFeedbackText: (state) => {
		return state.feedback.text;
	},

	getFeedbackType: (state) => {
		return state.feedback.type;
	},

	isFeedbackShow: (state) => {
		return state.feedback.show;
	},

	isLoadingLogs: (state) => {
		return state.isLoadingLogs;
	},
	isLoadingChilds: (state) => {
		return state.isLoadingChilds;
	},
	isLoadingContents: (state) => {
		return state.isLoadingContents;
	}
};

export default getters;
