<template>
    <div class="card">
        <img :src='require(`@/assets/img/icon${logoSrc}.svg`)' alt="" class="card--icon">
        <h2 class="card--title">
            <slot name="title"></slot>
        </h2>
        <hr>
        <p class="card--text">
            <slot name="text"></slot>
        </p>
        <MainButton btn="btn-primary">Acessar</MainButton>
    </div>
</template>

<script>
import MainButton from './buttons/MainButton.vue';


export default {
    props: {
        logoSrc: String,
        btnAdress: String,
    },
    components: { MainButton }
}
</script>

<style scoped>
.card {
    background: var(--color-light);
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    flex: 1;
    cursor: pointer;
    transition: box-shadow 0.25s ease;
    text-align: center;
}

.card:hover {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
}

.card--icon {
    margin-top: 1rem;
    width: 50%;
    max-width: 256px;
}

.card--title {
    color: var(--color-main);
    font-size: 1.5rem;
    font-weight: bold;
    margin: 2rem auto;
}

hr {
    color: var(--color-main);
    width: 60%;
}

.card--text {
    font-size: 1.2rem;
    font-weight: 300;
    margin: 2rem auto;
}

.btn {
    width: 60%;
}
</style>