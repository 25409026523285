const mutations = {
	setCourseInfo: (state, courseInfo) => {
		state.courseInfo = courseInfo;
	},

	setCourseId: (state, courseId) => {
		state.courseId = courseId;
	},

	setCourseChildren: (state, courseChildren) => {
		state.courseChildren = courseChildren;
	},

	setCourseContents: (state, courseContents) => {
		state.courseContents = courseContents;
	},

	setHasInfo: (state, hasInfo) => {
		state.hasInfo = hasInfo;
	},
	setIsLoading: (state, isLoading) => {
		state.isLoading = isLoading;
	},
	setIsLoadingLogs: (state, isLoadingLogs) => {
		state.isLoadingLogs = isLoadingLogs;
	},
	setIsLoadingChilds: (state, isLoadingChilds) => {
		state.isLoadingChilds = isLoadingChilds;
	},
	setIsLoadingContents: (state, isLoadingContents) => {
		state.isLoadingContents = isLoadingContents;
	},

	setFeedbackText: (state, text) => {
		state.feedback.text = text;
	},
	setFeedbackShow: (state, show) => {
		state.feedback.show = show;
	},
	setFeedbackType: (state, type) => {
		state.feedback.type = type;
	},

	setAvailability(state, { content_id, status }) {

		for (let i = 0; i < state.courseContents.length; i++) {
			const content = state.courseContents[i];

			if (content.id == content_id) {
				state.courseContents[i].availability.available = status ? "Yes" : "No";
			} else {
				if (content.hasChildren) {
					for (let y = 0; y < content.children.length; y++) {
						const child = content.children[y];
						if (child.id == content_id) {
							state.courseContents[i].children[y].availability.available = status ? "Yes" : "No";
						}
					}
				}
			}
		}

	},

	setLogs(state, logs) {

		logs.forEach(tempLog => {
			
			tempLog.logObj = JSON.parse(tempLog.log);

		});

		state.log = logs;


	}
};

export default mutations;
