const mutations = {
	setToken: (state, token) => {
		state.token = token;
	},
	setLogged: (state, logged) => {
		state.logged = logged;
	},
};

export default mutations;
